import PropTypes from 'prop-types'
import Verify from 'src/components/GettingStarted/components/Verify'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import styles from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'
import 'src/components/GettingStarted/gettingstarted.scss'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import Toast from 'src/components/Toast'
import useLoginModal from './hook'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'

const LoginModal = ({
    showModal,
    setShowModal,
    onCancel,
    loginModalContent,
    setLoginModalContent,
    inputs,
    otpMethod,
    setOtpMethod,
    initiateOTP,
    initiateOTPVerificationLoading,
    verifyApi,
    onVerifySuccess,
    preventConsumerRedirect = false,
    handlePostSuccess = () => {},
    showCloseIcon = true,
    initialDetailsNotProvided = false,
    modalTitle = 'Sign in to continue',
    modalHeading = 'How would you like to sign in?',
    modalSubHeading = 'A one time password will be send to the option you select for you to login',
}) => {
    const {
        methodDiv,
        methodDivActive,
        methodIcon,
        methodText,
        customStrokeAndFillStyle,
        customStrokeAndFillStyleIcon,
    } = styles

    const { isMobile } = useMediaQueries()
    const {
        handleClose,
        fullMethodName,
        handleClick,
        verifyLoginMutation,
        initiateLoginLoading,
        toastError,
        verifyLoginToastErr,
        errorMessage,
        verifyLoginErrMsg,
        otpMethods,
        handleSelectOtpMethod,
        errors,
        otp,
        setOtp,
        otpError,
        resendOTPMutation,
        resent,
        setResent,
        localInputs,
        handleOnBlur,
        handleChange,
        loginPayload,
    } = useLoginModal(
        onCancel,
        loginModalContent,
        setLoginModalContent,
        inputs,
        setShowModal,
        otpMethod,
        setOtpMethod,
        initiateOTP,
        verifyApi,
        onVerifySuccess,
        preventConsumerRedirect,
        handlePostSuccess,
        initialDetailsNotProvided,
    )

    const padding = isMobile ? 20 : '32px 42px 60px 42px'

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={handleClose}
            title={
                loginModalContent === 'enter-otp'
                    ? `Verify your ${fullMethodName}`
                    : modalTitle
            }
            footerLeftContent="chat"
            primaryBtnText="Continue"
            handlePrimaryBtnClick={handleClick}
            primaryBtnLoading={
                verifyLoginMutation.isLoading ||
                initiateLoginLoading ||
                initiateOTPVerificationLoading
            }
            standardHeight={'fit-content'}
            standardWidth={476}
            mobileHeight={527}
            standardContentStyle={{ padding: padding, height: 294 }}
            mobileContentStyle={{ padding: padding }}
            showCloseIcon={showCloseIcon}
            closable={false}
        >
            {(toastError || verifyLoginToastErr) && (
                <Toast
                    messageType={'error-secondary'}
                    message={errorMessage || verifyLoginErrMsg}
                />
            )}
            {loginModalContent === 'select-otp-method' && (
                <>
                    <div
                        className={'GettingStartedHeadingAndText'}
                        style={{ marginBottom: 15 }}
                    >
                        <h3>{modalHeading}</h3>
                        <p>{modalSubHeading}</p>
                    </div>
                    {otpMethods?.map((each, i) => (
                        <div
                            key={i}
                            role="button"
                            className={`${methodDiv} ${
                                otpMethod === each?.key ? methodDivActive : ''
                            } ${
                                each?.key === 'phone'
                                    ? customStrokeAndFillStyle
                                    : ''
                            }`}
                            onClick={() =>
                                !each.disabled &&
                                handleSelectOtpMethod(each?.key)
                            }
                            style={{
                                cursor: each.disabled ? 'not-allowed' : '',
                                border: each.disabled
                                    ? '0.5px solid #e5e5e5'
                                    : '',
                            }}
                        >
                            <div
                                className={`${methodIcon} ${
                                    each?.key === 'phone'
                                        ? customStrokeAndFillStyleIcon
                                        : ''
                                }`}
                            >
                                {each?.icon}
                            </div>
                            <div className={methodText}>
                                <h4>{each?.title}</h4>
                                <p>{each?.description}</p>
                            </div>
                        </div>
                    ))}
                    <p
                        className="GettingStartedOTPErrorMsg"
                        style={{ marginTop: 0 }}
                    >
                        {errors?.otpMethod}
                    </p>
                </>
            )}
            {loginModalContent === 'enter-contact-details' && (
                <div style={{ height: 285 }}>
                    <div
                        className={'GettingStartedHeadingAndText'}
                        style={{ marginBottom: 15 }}
                    >
                        <h3>Enter your {fullMethodName}</h3>
                        <p>
                            An OTP will be sent to your {fullMethodName} for you
                            to sign in
                        </p>
                    </div>
                    <div>
                        {otpMethod === 'email' ? (
                            <InputFields
                                name="email"
                                value={localInputs?.email}
                                inputValue={localInputs?.email}
                                handleChange={handleChange}
                                paddingBottom="18px"
                                title={'Enter your email address'}
                                errorMessage={errors?.email}
                                onBlur={handleOnBlur}
                            />
                        ) : (
                            <SelectCountryCode
                                title="Enter your phone number"
                                name="phone"
                                handleChange={handleChange}
                                inputValue={localInputs?.phone}
                                errorMessage={errors?.phone}
                                onBlur={handleOnBlur}
                            />
                        )}
                    </div>
                </div>
            )}
            {loginModalContent === 'enter-otp' && (
                <Verify
                    contact={otpMethod}
                    otp={otp}
                    setOtp={setOtp}
                    otpError={otpError}
                    inputs={loginPayload}
                    handleResendOtp={() => resendOTPMutation.mutate()}
                    resent={resent}
                    setResent={setResent}
                    disabled={verifyLoginMutation.isLoading}
                    padding={0}
                    margin={0}
                    minHeight={285}
                />
            )}
        </ConsumerModalBase>
    )
}

LoginModal.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    onCancel: PropTypes.func,
    loginModalContent: PropTypes.string,
    setLoginModalContent: PropTypes.func,
    inputs: PropTypes.object,
    otpMethod: PropTypes.string,
    setOtpMethod: PropTypes.func,
    initiateOTP: PropTypes.func,
    initiateOTPVerificationLoading: PropTypes.bool,
    verifyApi: PropTypes.func,
    onVerifySuccess: PropTypes.func,
    preventConsumerRedirect: PropTypes.bool,
    handlePostSuccess: PropTypes.func,
    showCloseIcon: PropTypes.bool,
    initialDetailsNotProvided: PropTypes.bool,
    modalTitle: PropTypes.string,
    modalHeading: PropTypes.string,
    modalSubHeading: PropTypes.string,
}

export default LoginModal
