import ChatCTA from 'src/components/ChatCTA'
import styles from '../solarsystemslistingsv2.module.scss'
import PropTypes from 'prop-types'
import SolarCity from 'src/assets/images/frame.svg'
import HandShake from 'src/assets/images/handshake.svg'
import ButtonComponent from 'src/components/Button'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { useSharedForm } from '../hooks/useSharedForm'
import '../sharedform.scss'
import { InlineLoader } from 'src/components/Loader'
import { useSolarSystemsContext } from '../hooks/useSolarSystems'
import modalBg from 'src/assets/images/shared-modal-bg.svg'

const SharedForm = ({
    view,
    openPageActionModal,
    closeSharedModal,
    setPageLoginInputs,
    setPageReturningCustomerId,
    openLoginNoDetailsProvided,
    setHasDiscardedDeal,
}) => {
    const {
        SharedFormWrapper,
        ClosedClusterWrapper,
        SharedFormWrapper_Returning,
        RightSection,
        RightBg1,
    } = styles
    const isPackage = view?.isPackage

    const {
        isClosedCluster,
        getEnergyProfileData,
        openActionModal,
        setLoginInputs,
        setReturningCustomerId,
    } = useSolarSystemsContext()
    const closedClusterData = getEnergyProfileData?.data?.data

    const viewSpecificOpenActionModal =
        view?.isPackage === true ? openPageActionModal : openActionModal
    const viewSpecificSetLoginInputs =
        view?.isPackage === true ? setPageLoginInputs : setLoginInputs
    const viewSpecificSetReturningCustomerId =
        view?.isPackage === true
            ? setPageReturningCustomerId
            : setReturningCustomerId

    const {
        errors,
        inputs,
        setInputs,
        handleInputChange,
        validateUserIdMutation,
        handleGetStarted,
        registerDropOffLoading,
        handleClosedClusterGetStarted,
        getReturningCustomerStatusLoading,
    } = useSharedForm(
        closedClusterData,
        isClosedCluster,
        viewSpecificOpenActionModal,
        closeSharedModal,
        viewSpecificSetLoginInputs,
        viewSpecificSetReturningCustomerId,
        setHasDiscardedDeal,
    )

    const handleSelectChange = (name, value, errors, setInputs) => {
        setInputs(prev => ({ ...prev, [name]: value }))
        if (errors[name]) {
            delete errors[name]
        }
    }

    const closedClusterLogo = closedClusterData?.cluster_origination?.logo
    const showReturningCustomerFlow =
        process.env
            .REACT_APP_FLAG_RETURNING_CUSTOMERS_ON_SHARED_PROFILES_AND_PACKAGES ===
        'true'

    return (
        <div
            className={`${SharedFormWrapper} ${
                isClosedCluster && ClosedClusterWrapper
            } ${showReturningCustomerFlow ? SharedFormWrapper_Returning : ''}`}
        >
            <div>
                <div>
                    <img
                        src={closedClusterLogo ?? HandShake}
                        alt="handshake"
                        width={234}
                        height={104}
                    />
                    <h2>
                        {isClosedCluster
                            ? `Hey, welcome to SunFi!`
                            : `Welcome to SunFi`}
                    </h2>
                    {isPackage ? (
                        <h3>
                            The link to this package;
                            <span> {view.name} </span>
                            has been shared with you. Please enter your email
                            address to continue.
                        </h3>
                    ) : isClosedCluster ? (
                        <>
                            <h3>
                                You have been invited to select a system. Select
                                a system that best fit your energy needs
                            </h3>
                        </>
                    ) : (
                        <h3>
                            We&apos;re thrilled to have you here. Feel free to
                            explore and discover all that we have to offer.
                            Please enter your email address to continue.
                        </h3>
                    )}

                    <div>
                        <div className={'SharedFormNewInputsWrapper'}>
                            {isClosedCluster && (
                                <div className="NewFormInputsClosedClusterText">
                                    <h4>Add your work email address</h4>
                                    <p>
                                        Ensure the provided email is your work
                                        email address.
                                    </p>
                                </div>
                            )}
                            <InputFields
                                name="firstName"
                                value={inputs.firstName}
                                handleChange={handleInputChange}
                                paddingBottom="18px"
                                title="Enter your first name"
                                errorMessage={errors['firstName']}
                                exemptMouseOut
                            />
                            <InputFields
                                name="email"
                                value={inputs.email}
                                handleChange={handleInputChange}
                                paddingBottom="18px"
                                title={
                                    isClosedCluster
                                        ? 'Your work email address'
                                        : 'Enter your email address'
                                }
                                errorMessage={
                                    errors['email'] ||
                                    errors['checkExistingStatus']
                                }
                                activateEnterClick
                            />
                            <SelectCountryCode
                                handleChange={handleInputChange}
                                handleSelectChange={(name, value) =>
                                    handleSelectChange(
                                        name,
                                        value,
                                        errors,
                                        setInputs,
                                    )
                                }
                                title="Enter your phone number"
                                name="phone"
                                inputValue={inputs.phone}
                                errorMessage={
                                    errors['phone'] ||
                                    errors['checkExistingStatus']
                                }
                                sseUse={true}
                                activateEnterClick
                            />
                        </div>
                    </div>

                    <div>
                        <ButtonComponent
                            btnBgColor="#004AAD"
                            btnTextColor="#fff"
                            btnWidth={'100%'}
                            marginTop={'16px'}
                            handleClick={() => {
                                isClosedCluster
                                    ? handleClosedClusterGetStarted()
                                    : handleGetStarted()
                            }}
                        >
                            {registerDropOffLoading ||
                            validateUserIdMutation.isLoading ||
                            getReturningCustomerStatusLoading ? (
                                <InlineLoader />
                            ) : (
                                'Get Started'
                            )}
                        </ButtonComponent>
                        {showReturningCustomerFlow ? (
                            <div
                                className={styles.modalSignInBtn}
                                role="button"
                                onClick={() =>
                                    openLoginNoDetailsProvided?.('shared-form')
                                }
                            >
                                <p>Already have an account?</p>
                                <span>Sign in</span>
                            </div>
                        ) : (
                            <ChatCTA />
                        )}
                    </div>
                </div>
                <div>
                    {showReturningCustomerFlow ? (
                        <div className={RightSection}>
                            <img
                                src={modalBg}
                                alt="SunFi"
                                className={RightBg1}
                            />
                        </div>
                    ) : (
                        <img
                            src={SolarCity}
                            alt="shared-form-layout-right-img"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

SharedForm.propTypes = {
    view: PropTypes.object,
    openPageActionModal: PropTypes.func,
    closeSharedModal: PropTypes.func,
    setPageLoginInputs: PropTypes.func,
    setPageReturningCustomerId: PropTypes.func,
    openLoginNoDetailsProvided: PropTypes.func,
    setHasDiscardedDeal: PropTypes.func,
}

export default SharedForm
